import React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ResistorCalcPage = ({ data, location }) => {

  return (
    <Layout location={location} title="E-Series Calculator">
      <SEO title="E-Series Calculator Webtool" lang="en" />
      <h1>E-Series Calculator Webtool</h1>
      <p>We are almoste there use the german version instead <a href="/toolbox/widerstand-e-reihe-bestimmen">E-Series Calculator Webtool</a>.</p>
    </Layout>
  )
}

export default ResistorCalcPage